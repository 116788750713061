import React from "react";

import Forms from "./components/Forms";

import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  
  return (
    <div className="container">
      <Header />
      <Forms />
      <Footer />
    </div>
  );
}

export default App;
