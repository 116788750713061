import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";

const API = "/api/meeting";

const FormPage = (props) => {
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    email: "",
    first_name: "",
    last_name: "",
    company: "",
  });
  const [industry, setIndustry] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [industryErrorMessage, setIndustryErrorMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);

  let params = new URLSearchParams(document.location.search);
  let uid = params.get("uid");

  const handleInputChange = (event) => {
    setInfo({ ...info, [event.target.name]: event.target.value });
    if (event.target.value.length === 0) {
      setErrorMessage(null);
    }
  };

  const handleSubmit = async () => {
    setDisabled(true);
    setErrorMessage(null);
    setIndustryErrorMessage(null);

    const payload = { ...info, industry: industry.toUpperCase() };

    const response = await fetch(`${API}/${uid}/validate_email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      props.email.current = info.email;
      navigate("/thankyou");
    } else if (response.status === 400) {
      const data = await response.json();
      if (data.message.indexOf("email") >= 0) {
        setErrorMessage(
          "Sorry, that email address is not valid, please try again"
        );

        if (industry == null || industry.length === 0) {
          setIndustryErrorMessage("Please select an industry");
        }
      } else if (data.message.indexOf("industry") >= 0) {
        setIndustryErrorMessage("Please select an industry");
      }
    }

    setDisabled(false);
  };

  const RadioButton = ({ title }) => {
    return (
      <div className="radio-button-container">
        <button
          className={`radio-button ${
            industry === title ? "radio-button-highlight" : ""
          }`}
          onClick={() => {
            setIndustry(title);
            setIndustryErrorMessage(null);
          }}
        >
          {title}
        </button>
      </div>
    );
  };

  const KeyPressHandler = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  };

  return (
    <AnimatedPage>
      <div className="input-form-container">
        <div className="submission-form-background">
          <div className="submission-form-content">
            <div className="submission-form-header">
              <div className="submission-form-title">App access</div>
              <div className="submission-form-subtitle">
                To enter the experience, please complete the form below.
              </div>
            </div>
            <fieldset
              className={`custom-fieldset ${
                errorMessage == null ? "" : "custom-fieldset-error"
              }`}
            >
              <legend
                className={`custom-legend ${
                  errorMessage == null ? "" : "custom-text-error"
                }`}
              >
                YOUR EMAIL
              </legend>
              <div className="custom-input-container">
                <input
                  className={`custom-input ${errorMessage == null ? "" : ""}`}
                  name="email"
                  type="email"
                  value={info.email}
                  onChange={handleInputChange}
                  onKeyPress={KeyPressHandler}
                  autoComplete="off"
                ></input>
              </div>
            </fieldset>
            <div
              className={`email-error-message ${
                errorMessage == null ? "" : "email-show-error"
              }`}
            >
              Sorry, that email address is not valid, please try again
            </div>
            <div>
              <fieldset
                className={`custom-fieldset ${
                  industryErrorMessage == null ? "" : "custom-fieldset-error"
                }`}
              >
                <legend
                  className={`custom-legend ${
                    industryErrorMessage == null ? "" : "custom-text-error"
                  }`}
                >
                  YOUR INDUSTRY
                </legend>
                <RadioButton title="Private / business aviation" />
                <RadioButton title="Commercial aviation" />
                <RadioButton title="Maritime" />
                <RadioButton title="Other" />
              </fieldset>
            </div>
            <div
              className={`email-error-message ${
                industryErrorMessage == null ? "" : "email-show-error"
              }`}
            >
              Please select an industry
            </div>
            {!disabled && (
              <button
                className="submit-button button-text"
                onClick={handleSubmit}
                type="submit"
                disabled={disabled}
              >
                Access the app
              </button>
            )}
            {disabled && (
              <div className="submit-button">
                <div className="loader"></div>
              </div>
            )}
            <div className="submission-form-disclaimer">
              <p className="submission-form-disclaimer-text">
                By clicking ‘Submit’ you are agreeing to the Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};
export default FormPage;
