import { AnimatePresence, easeInOut, motion } from "framer-motion";

// const animations = {
//   initial: { opacity: 0, y: 200 },
//   animate: { opacity: 1, y: 0 },
//   transition: { type: 'spring', bounce: 0.25, duration: 2},
//   exit: { opacity: 0, y: -200 },
// };
const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: {easeInOut, duration: 0.3},
  exit: { opacity: 0 },
};

const AnimatedPage = ({ children,className, exitCallback, isExiting }) => {
  return (
    <AnimatePresence mode="sync" onExitComplete={exitCallback}>
      {!isExiting && (<motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={animations.transition}
        className={className}
      >
        {children}
      </motion.div>)}
    </AnimatePresence>
  );
};

export default AnimatedPage;
