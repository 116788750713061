import React from "react";
import { useNavigate } from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";
import appStore from "../assets/app-store.webp";
import playStore from "../assets/play-store.webp";

const ThankYou = (props) => {
  const navigateto = useNavigate();

  const Conitnue = async () => {
    let params = new URLSearchParams(document.location.search);
    let uid = params.get("uid");
    navigateto("/passcode");
  };

  return (
      <div className="thank-you-page-container">
        <div>
          <div className="thank-you-form-background">
            <div className="thank-you-content">
              <div className="thank-you-title">Thank you!</div>
              <div className="thank-you-subtitle">
                Please check your email for instructions to access the app.
              </div>

              <BrowserView>
                <a
                  className="webgl-link"
                  href={`https://viasat-webgl.e-showroom.net/?email=${props.email.current}`}
                  target="_blank"
                >
                  <button
                    className="thank-you-button button-text"
                    onClick={Conitnue}
                  >
                    Access the app
                  </button>
                </a>
              </BrowserView>

              <MobileView>
                <div className="store-links">
                  <a href="https://apps.apple.com/app/id6444257759">
                    <img src={appStore} />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.viasat.capacityunleashed">
                    <img src={playStore} />
                  </a>
                </div>
              </MobileView>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ThankYou;
