import React, { useRef } from 'react';
import { Routes, Route, Navigate, MemoryRouter } from 'react-router-dom';

import Homepage from '../Pages/Homepage';
import ThankYou from '../Pages/ThankYouForm';
import FormPage from '../Pages/FormPage';
const Forms = () => {
  const email = useRef(null)
  return (
    <MemoryRouter>
      <Routes>
        <Route path='/' element={<Homepage email={email}/>} />
        <Route path='/email' element={<FormPage email={email} />} />
        <Route path='/thankyou' element={<ThankYou email={email} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </MemoryRouter>
  );
}

export default Forms;