import logo from '../assets/logo.svg'

const Header = () => {
  return (
    <div className='header'>
      <a href='/'><img src={logo}/></a>
    </div>
  )
}

export default Header