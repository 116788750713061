import React from "react";
import { useEffect } from "react";

import Landing from "./LandingForm";

const Homepage = (props) => {
  useEffect(() => {
    // Add the class to the body when the component mounts
    document.body.classList.add("home-page-background-image");

    // Remove the class from the body when the component unmounts
    return () => {
      document.body.classList.remove("home-page-background-image");
    };
  }, []); // Empty dependency array ensures this runs once on mount and unmount
  return (


      <Landing />

  );
};

export default Homepage;
