const Footer = () => {
  return (
    <div className='header-footer footer'>
      <div className="inc">© 2024 Viasat, Inc.</div>
      <div className="info-container">
      <div><a href='https://www.viasat.com/content/dam/us-site/legal/documents/ViasatGeneralPrivacyNoticeUpdated-EN.pdf' target={'_blank'} className='privacy-policy'>Privacy</a></div>
      <div><a href='mailto:viasat@neutral.digital' target={'_blank'} className='contact'>Contact us</a></div>
      </div>
    </div>
  )
}

export default Footer