import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";

const Landing = () => {
  const navigateto = useNavigate();
  const [isExiting, setIsExiting] = useState(false);

  const handleSubmit = () => {
    // navigateto("/email");
    setIsExiting(true);
  };

  const handleExit = () => {
    navigateto("/email");
  }

  return (
    <div className="landing-page">
      <div>
        <AnimatedPage exitCallback={handleExit} isExiting={isExiting}>
        <div className="landing-header">Experience capacity unleashed</div>
        <div className="landing-subtitle">
          Viasat is unleashing capacity to deliver game-changing experiences,
          empower operations and unlock limitless opportunities for a world on
          the move. Explore the Capacity Unleashed App to see how.{" "}
        </div>
        <button className="form-button button-text" onClick={handleSubmit}>
          Access the app
        </button>
        </AnimatedPage>
      </div>
    </div>
  );
};

export default Landing;
